import styled from 'styled-components';
import { white, pinkFont, blueFont, buttonHover } from '../../constants/theme';

export const ContactFormContainer = styled.div`
  .container-border {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin: 30px 15px 120px 15px;
  }
  .subtitle {
    padding: 16px 16px 0px 16px;
    margin-bottom: 16px;
    color: #212529;
  }
  form {
    width: 100%;
    padding: 0 16px 16px 16px;
    p {
      margin-top: 13px;
    }
    label {
      font-size: 16px;
      line-height: 1.5;
      color: #212529;
    }
    input {
      width: 100%;
      margin-top: 12px;
      margin-bottom: 16px;
      border: 1px solid #ced4da;
      color: #495057;
      font-size: 20px;
      line-height: 1.5;
      padding: 8px 16px;
    }
    select {
      height: 48px;
      width: 100%;
      margin-top: 8px;
      margin-bottom: 16px;
      border: 1px solid #ced4da;
      color: #495057;
      font-size: 14px;
      line-height: 1.5;
      padding: 8px 16px;
      background: transparent;
      ::-ms-expand {
        -webkit-appearance: none;
        background-color: transparent;
        border: 0;
      }
    }
    .note {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      p {
        color: #212529;
        margin: 30px 0;
      }
    }
    textarea {
      width: 100%;
      margin-top: 8px;
      border: 1px solid #ced4da;
      color: #495057;
      font-size: 16px;
      line-height: 1.5;
      padding: 6px 12px;
    }
    .textarea-col {
      margin-bottom: 24px;
    }
    input,
    select,
    textarea {
      border-radius: 0;
      :focus {
        background-color: #fff;
        outline: 0;
        border-color: #80bdff;
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
      }
    }
    input,
    textarea {
      -webkit-appearance: none;
    }
    #grippie {
      background: #eee url(/icons/grippie.png) no-repeat center 2px;
      border: 1px solid #ddd;
      border-top-width: 0;
      cursor: s-resize;
      height: 9px;
      position: relative;
      top: -3px;
      z-index: -1;
    }
    select:focus::-ms-value {
      color: black;
      background: transparent;
    }
  }
  .recaptcha-terms {
    p {
      margin: 0;
    }
    a {
      color: ${blueFont};
      :hover {
        text-decoration: underline;
      }
    }
    margin-bottom: 24px;
  }
  .submit-btn {
    background-color: ${pinkFont};
    border: solid 1px ${pinkFont};
    border-radius: 0;
    color: ${white};
    font-size: 16px;
    line-height: 1.5;
    padding: 6px 57px;
    float: right;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 16px;
    margin-right: 4px;
    :focus {
      outline: none;
    }
    :hover {
      background-color: ${buttonHover};
      border-color: ${buttonHover};
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 1199px) {
    .submit-btn {
      padding: 6px 48px;
    }
  }
  @media screen and (max-width: 991px) {
    .container-border {
      margin: 58px 15px 100px 15px;
    }
  }
  @media screen and (max-width: 767px) {
    .container-border {
      margin: 58px 15px;
    }
    .submit-btn {
      padding: 6px 12px;
    }
  }
`;
