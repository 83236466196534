import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import ContactForm from '../components/form/contact-us-form';
import { Fonts, Container, ContactPageContainer } from '../global.css';

const ContactPage = ({ data }) => {
  return (
    <Layout meta={data.contactUsJson.seo}>
      <Container>
        <Fonts>
          <ContactPageContainer>
            <ContactForm />
          </ContactPageContainer>
        </Fonts>
      </Container>
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query ContactBayerQuery {
    contactUsJson {
      seo {
        pageTitle
        pageDescription
      }
    }
  }
`;

export default ContactPage;
