import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { Row, Col, Label } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { submitFormUrl, reCaptchaKey } from '../../constants/config';
import { ContactFormContainer } from './contact-us-form.css';
import { trackAnalytics } from '../../helpers/trackAnalytics';
import { masterDataContext } from '../../store/masterDataContext';

const ContactForm = ({ data }) => {
  const { masterData } = useContext(masterDataContext);
  const [responseOk, setResponse] = useState(false);
  const [errorsList, setErrorsList] = useState({});

  useLayoutEffect(() => {
    makeResizeableTextarea('textarea');
  }, []);

  useEffect(() => {
    trackAnalytics('ContactUsShow', {
      title: 'Contact Us',
      TrialId: '',
      TrialTitle: '',
    });
  }, []);

  async function formSubmit(values, actions) {
    if (values.country.toLocaleLowerCase() === 'select country') {
      values.country = '';
    }
    let jsonObject = {
      TrialId: '',
      TrialTitle: '',
      From: `${values.firstName} ${values.lastName}`,
      Email: values.email,
      Phone: values.phone,
      Country: values.country,
      Message: values.message,
      LocationName: '',
      LocationAddress: '',
      gRecaptcha: await executeRecaptcha(),
    };

    trackAnalytics(
      'ContactUsSubmit',
      //Destructuring the jsonObject to select just the needed fields
      (({
        TrialId,
        TrialTitle,
        Country,
        Message,
        LocationName,
        LocationAddress,
      }) => ({
        TrialId,
        TrialTitle,
        Country,
        Message,
        LocationName,
        LocationAddress,
      }))(jsonObject)
    );

    if (typeof window !== 'undefined' && window.ga) {
      try {
        window.ga.getAll().forEach(_ga => {
          _ga.send('event', 'Mails', 'Mails', data.contactUsJson.contactTitle);
        });
      } catch (ev) {
        console.log(ev);
      }
    }

    await fetch(submitFormUrl(), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonObject),
    })
      .then(response => {
        if (response.status === 204) {
          return {
            status: 'options',
          };
        }
      })
      .then(() => {
        setResponse(true);
        actions.resetForm();
        actions.setSubmitting(false);
      });
  }

  async function executeRecaptcha() {
    return new Promise(resolve => {
      window.grecaptcha.execute(reCaptchaKey()).then(res => {
        resolve(res);
      });
    });
  }

  const makeResizeableTextarea = area => {
    let textarea = document.getElementById(area);
    let grippie = document.getElementById('grippie');
    const minimum_size = 84;
    let original_height = 0;
    let original_mouse_y = 0;
    grippie.addEventListener('mousedown', function(e) {
      e.preventDefault();
      original_height = parseFloat(
        getComputedStyle(textarea, null)
          .getPropertyValue('height')
          .replace('px', '')
      );
      original_mouse_y = e.pageY;
      textarea.style.opacity = 0.25;
      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopResize);
    });
    function resize(e) {
      const height = original_height + (e.pageY - original_mouse_y);
      if (height > minimum_size) {
        textarea.style.height = height + 'px';
      }
    }
    function stopResize() {
      textarea.style.opacity = 1;
      window.removeEventListener('mousemove', resize);
    }
  };

  const setErrors = errors => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    setErrorsList(errors);
  };

  return (
    <ContactFormContainer>
      {responseOk ? (
        <div className="msg-sended">
          <p className={'thank-you-msg'}>{data.contactUsJson.thankYouMsg}</p>
        </div>
      ) : null}
      {errorsList.firstName || errorsList.lastName || errorsList.email ? (
        <div className="error-list">
          <ul>
            {errorsList.firstName ? <li>{errorsList.firstName}</li> : null}
            {errorsList.lastName ? <li>{errorsList.lastName}</li> : null}
            {errorsList.email ? <li>{errorsList.email}</li> : null}
          </ul>
        </div>
      ) : null}
      <div className="container-border">
        <h1>{data.contactUsJson.contactTitle}</h1>
        <p className="subtitle">{data.contactUsJson.subtitle}</p>
        <Formik
          enableReinitialize={false}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            country: '',
            message: '',
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required(
              data.contactUsJson.errors.firstName
            ),
            lastName: Yup.string().required(data.contactUsJson.errors.lastName),
            email: Yup.string()
              .email(data.contactUsJson.errors.emailValid)
              .required(data.contactUsJson.errors.email),
          })}
          onSubmit={formSubmit}
          render={({ isSubmitting, validateForm }) => (
            <Form className="modal-form-style">
              <Row>
                <Col md={6} xs={12}>
                  <Label for={'firstname-field'}>
                    {`${data.contactUsJson.personalDataInputs[0].inputLabel} *`}
                  </Label>
                  <Field
                    id="firstname-field"
                    type="text"
                    name="firstName"
                    maxLength="128"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Label for={'lastname-field'}>
                    {`${data.contactUsJson.personalDataInputs[1].inputLabel} *`}
                  </Label>
                  <Field
                    id="lastname-field"
                    type="text"
                    name="lastName"
                    maxLength="128"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Label for={'email-field'}>
                    {`${data.contactUsJson.personalDataInputs[2].inputLabel} *`}
                  </Label>
                  <Field
                    id="email-field"
                    type="text"
                    name="email"
                    maxLength="128"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Label for={'phone-field'}>
                    {data.contactUsJson.personalDataInputs[3].inputLabel}
                  </Label>
                  <Field
                    id="phone-field"
                    type="text"
                    name="phone"
                    maxLength="128"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Label for={'country-field'}>
                    {data.contactUsJson.personalDataInputs[4].inputLabel}
                  </Label>
                  <Field id="country-field" component="select" name="country">
                    <option>
                      {
                        data.contactUsJson.personalDataInputs[4]
                          .inputPlaceholder
                      }
                    </option>
                    {masterData?.COUNTRY?.sort(
                      (a, b) => a.SortOrder - b.SortOrder
                    ).map(country => (
                      <option
                        key={country.InternalValue}
                        value={country.DisplayValue}
                      >
                        {country.DisplayValue}
                      </option>
                    ))}
                  </Field>
                </Col>
                <Col md={6} xs={12} className="note">
                  <p>{data.contactUsJson.note}</p>
                </Col>
                <Col xs={12} className="textarea-col">
                  <Label for={'textarea'}>
                    {data.contactUsJson.msgInputLabel}
                  </Label>
                  <Field
                    id="textarea"
                    component="textarea"
                    name="message"
                    maxLength="5000"
                    cols="60"
                    rows="3"
                  />
                  <div id="grippie" />
                </Col>
                <Col xs={12}>
                  <div
                    className="recaptcha-terms"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.contactUsJson.recaptchaTerms.childMarkdownRemark
                          .html,
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <button
                    className={'submit-btn'}
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      setResponse(false);
                      validateForm().then(error => {
                        setErrors(error);
                      });
                    }}
                  >
                    {data.contactUsJson.submitBtn}
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        />
      </div>
    </ContactFormContainer>
  );
};

ContactForm.propTypes = {
  data: PropTypes.object,
};

const ContactUsQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        contactUsJson {
          contactTitle
          subtitle
          personalDataInputs {
            inputLabel
            inputName
            inputPlaceholder
          }
          note
          msgInputLabel
          msgInputName
          submitBtn
          thankYouMsg
          recaptchaTerms {
            childMarkdownRemark {
              html
            }
          }
          errors {
            firstName
            lastName
            email
            emailValid
          }
        }
      }
    `}
    render={data => <ContactForm data={data} {...props} />}
  />
);

export default ContactUsQuery;
